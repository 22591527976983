import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunk from "redux-thunk";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import "./index.css";
import App from "./App";

import serviceWorker from "./serviceWorker";

import loginReducer from "./store/reducers/login";
import usersReducer from "./store/reducers/users";
import rolesReducer from "./store/reducers/roles";
import assignRoleReducer from "./store/reducers/assignRole";
import availableRoleReducer from "./store/reducers/AvailableRole";
import permissionsReducer from "./store/reducers/Permissions";
import userInfoReducer from "./store/reducers/userInfo";
import resetPasswordReducer from "./store/reducers/resetPassword";
import addUsersReducer from "./store/reducers/addUser";
import organizationsReducer from "./store/reducers/organizations";
import accessReportReducer from "./store/reducers/accessReport";
import inboxReducer from "./store/reducers/inbox";
import userActivityReducer from "./store/reducers/userActivity";
import partnerApplicationDetailReducer from "./store/reducers/partnerApplicationDetail";
import partnerApplicationAttachmnetReducer from "./store/reducers/partnerApplicationAttachmnet";
import partnerAppAttachmentListReducer from "./store/reducers/PartnerAppAttachmentList";
import partnerApplicationWFLogListReducer from "./store/reducers/PartnerApplicationWFLogList";
import vouchersReducer from "./store/reducers/vouchers";
import voucherInfoReducer from "./store/reducers/voucherInfo";
import addVoucherReducer from "./store/reducers/addVoucher";
import voucherProductsReducer from "./store/reducers/VoucherProducts";
import productLimitTovoucherReducer from "./store/reducers/productLimitTovoucher";
import addVoucherBatchReducer from "./store/reducers/addVoucherBatch";
import VoucherBatchesReducer from "./store/reducers/voucherBatches";
import voucherCodeReducer from "./store/reducers/voucherCode";
import templateReducer from "./store/reducers/template";
import voucherCodeCheckerReducer from "./store/reducers/voucherCodeChecker";
import loyaltyRuleReducer from "./store/reducers/loyaltyRule";
import addLoyaltyRuleReducer from "./store/reducers/addLoyaltyRule";
import backOfficeReducer from "./store/reducers/backOffcie";

import addTrainStatusReducer from "./store/reducers/addTrainStatus";

import voucherApprovalReducer from "./store/reducers/voucherApproval";

// cust reducer
import customersReducer from "./store/reducers/customers";


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  org: organizationsReducer,
  auth: loginReducer,
  users: usersReducer,
  //customers
  customers: customersReducer,
  addUser: addUsersReducer,
  userInfo: userInfoReducer,
  resetPassword: resetPasswordReducer,
  roles: rolesReducer,
  permissions: permissionsReducer,
  assignRole: assignRoleReducer,
  accessReport: accessReportReducer,
  availableRole: availableRoleReducer,
  inbox: inboxReducer,
  userActivity: userActivityReducer,
  partnerApplicationDetail: partnerApplicationDetailReducer,
  partnerApplicationAttachmnet: partnerApplicationAttachmnetReducer,
  partnerAppAttachmentList: partnerAppAttachmentListReducer,
  PartnerApplicationWFLogList: partnerApplicationWFLogListReducer,
  vouchers: vouchersReducer,
  voucherInfo: voucherInfoReducer,
  partnerApplicationWFLogList: partnerApplicationWFLogListReducer,
  addVoucher: addVoucherReducer,
  voucherProducts: voucherProductsReducer,
  productLimitTovoucher: productLimitTovoucherReducer,
  addVoucherBatch: addVoucherBatchReducer,
  voucherBatches: VoucherBatchesReducer,
  voucherCode: voucherCodeReducer,
  template: templateReducer,
  voucherCodeChecker: voucherCodeCheckerReducer,
  loyaltyRule: loyaltyRuleReducer,
  addLoyaltyRule: addLoyaltyRuleReducer,
  backOffice: backOfficeReducer,
  addTrainStatus: addTrainStatusReducer,
  voucherApproval: voucherApprovalReducer,
});

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk)),
);

const app = (
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

ReactDOM.render(app, document.getElementById("root"));
serviceWorker();
