import service from "../../services/userManagement.service";
import * as actionTypes from "./actionTypes";
import { debug } from "util";

export const resetPasswordStart = () => {
  console.log("LIST_USERS_START");
  return {
    type: actionTypes.RESET_PASSWORD_START
  };
};

export const resetPasswordSuccess = userInfo => {
  return {
    type: actionTypes.RESET_PASSWORD_SUCCESS,
    userInfo: userInfo
  };
};

export const resetPasswordFail = error => {
  return {
    type: actionTypes.RESET_PASSWORD_FAIL,
    error: error
  };
};

export const resetPasswordClear = () => {
  return {
    type: actionTypes.RESET_PASSWORD_CLEAR
  };
};

export const clear = () => {
  return dispatch => {
    dispatch(resetPasswordClear());
  };
};

export const reset = (u_id, password) => {
  return dispatch => {
    dispatch(resetPasswordStart());
    service
      .post(`/user/${u_id}/reset`, { password: password })
      .then(res => {
        console.log("service executed..................................");
        console.dir(res.data);
        dispatch(resetPasswordSuccess(res.data));
      })
      .catch(err => {
        console.log("Service Error ---------------------------------------");
        console.log(err);
        dispatch(resetPasswordFail(err));
      });
  };
};

export const clearMessage = () => {
  return {
    type: "CLEAR_MESSAGE"
  };
};

