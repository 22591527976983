import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../helpers/utility";

const initialState = {
  error: null,
  loading: false,
  message: null
};

const resetPasswordStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
    message: null
  });
};

const resetPasswordSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: null,
    message: "Password changed successfully."
  });
};

const resetPasswordFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
    message: null
  });
};

const clearMessage = (state, action) => {
  return {
    ...state,
    message:null
  }
}

const resetPasswordClear = (state, action) => {
  return updateObject(state, initialState);
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.RESET_PASSWORD_SUCCESS:
      return resetPasswordSuccess(state, action);
    case actionTypes.RESET_PASSWORD_FAIL:
      return resetPasswordFail(state, action);
    case actionTypes.RESET_PASSWORD_START:
      return resetPasswordStart(state, action);
    case actionTypes.RESET_PASSWORD_CLEAR:
      return resetPasswordClear(state, action);
    case actionTypes.CLEAR_MESSAGE:
      return clearMessage(state, action);
    default:
      return state;
  }
};

export default reducer;
