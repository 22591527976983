import service from "../../services/userManagement.service";
import * as actionTypes from "./actionTypes";
import listActions from "../../helpers/listActions";
const ListActions = listActions("LIST_CUSTOMERS");

export const selectCustomer = (row) => {
  return {
    type: actionTypes.LIST_CUSTOMERS_SELECT,
    selectedRow: row,
  };
};

export const listCustomers = (filterOptions, isExport = false) => {
  return (dispatch) => {
    dispatch(ListActions.start(isExport));
    console.log(
      `/user/customer?page_number=${filterOptions.currentPage}&page_size=${
        filterOptions.page_size
      }&org_id=${filterOptions.org.value}&u_type=${
        filterOptions.userType.value
      }&sort_by=${filterOptions.sort_by}&sort_dir=${filterOptions.sort_dir}${
        filterOptions.searchKeyword.length > 0 ? "&keyword=" : ""
      }${filterOptions.searchKeyword}`
    );
    service
      .get(
        `/user/customer?page_number=${filterOptions.currentPage}&page_size=${
          filterOptions.page_size
        }&org_id=${filterOptions.org.value}&u_type=${
          filterOptions.userType.value
        }&sort_by=${filterOptions.sort_by}&sort_dir=${filterOptions.sort_dir}${
          filterOptions.searchKeyword.length > 0 ? "&keyword=" : ""
        }${filterOptions.searchKeyword}`
      )
      .then((res) => {
        console.log("API Response Data:", res.data); // Log the response data
        dispatch(ListActions.success(res.data, filterOptions, isExport));
      })
      .catch((err) => {
        dispatch(ListActions.fail(err, filterOptions));
      });
  };
};
