export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const LOGIN_RESET_STATUS = "LOGIN_RESET_STATUS";
export const LOGIN_SET_STATUS = "LOGIN_SET_STATUS";
export const LOGIN_SET_MFA_STATUS = "LOGIN_SET_MFA_STATUS";
export const LOGIN_SET_LOCKED_STATUS = "LOGIN_SET_LOCKED_STATUS";
export const LOGIN_SET_EXPIRED_STATUS = "LOGIN_SET_EXPIRED_STATUS";
export const LOGIN_SET_FORGOTPASSWORD_STATUS =
  "LOGIN_SET_FORGOTPASSWORD_STATUS";

export const FORGOT_PASSWORD_END_EMAIL_START =
  "FORGOT_PASSWORD_END_EMAIL_START";
export const FORGOT_PASSWORD_END_EMAIL_SUCCESS =
  "FORGOT_PASSWORD_END_EMAIL_SUCCESS";
export const FORGOT_PASSWORD_END_EMAIL_FAIL = "FORGOT_PASSWORD_END_EMAIL_FAIL";
export const RESET_PASSWORD_END_CLEAN_MESSAGE =
  "RESET_PASSWORD_END_CLEAN_MESSAGE";
// reset message
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const FORGOT_PASSWORD_CHECK_TOKEN_START =
  "FORGOT_PASSWORD_CHECK_TOKEN_START";
export const FORGOT_PASSWORD_CHECK_TOKEN_SUCCESS =
  "FORGOT_PASSWORD_CHECK_TOKEN_SUCCESS";
export const FORGOT_PASSWORD_CHECK_TOKEN_FAIL =
  "FORGOT_PASSWORD_CHECK_TOKEN_FAIL";

export const FORGOT_PASSWORD_RESET_START = "FORGOT_PASSWORD_RESET_START";
export const FORGOT_PASSWORD_RESET_SUCCESS = "FORGOT_PASSWORD_RESET_SUCCESS";
export const FORGOT_PASSWORD_RESET_FAIL = "FORGOT_PASSWORD_RESET_FAIL";

export const LOGIN_CHANGE_PASSWORD_START = "LOGIN_CHANGE_PASSWORD_START";
export const LOGIN_CHANGE_PASSWORD_SUCCESS = "LOGIN_CHANGE_PASSWORD_SUCCESS";
export const LOGIN_CHANGE_PASSWORD_FAIL = "LOGIN_CHANGE_PASSWORD_FAIL";

export const RESET_PASSWORD_BACK_TO_LOGIN = "RESET_PASSWORD_BACK_TO_LOGIN";

export const LIST_USERS_SUCCESS = "LIST_USERS_SUCCESS";
export const LIST_USERS_FAIL = "LIST_USERS_FAIL";
export const LIST_USERS_START = "LIST_USERS_START";
export const LIST_USERS_SEARCHBOX_CHANGE = "LIST_USERS_SEARCHBOX_CHANGE";
export const LIST_USERS_SELECT = "LIST_USERS_SELECT";
export const LIST_USERS_EXPORT_START = "LIST_USERS_EXPORT_START";
export const LIST_USERS_EXPORT_SUCCESS = "LIST_USERS_EXPORT_SUCCESS";

// CUSTOMER
export const LIST_CUSTOMERS_SUCCESS = "LIST_CUSTOMERS_SUCCESS";
export const LIST_CUSTOMERS_FAIL = "LIST_CUSTOMERS_FAIL";
export const LIST_CUSTOMERS_START = "LIST_CUSTOMERS_START";
export const LIST_CUSTOMERS_SEARCHBOX_CHANGE = "LIST_CUSTOMERS_SEARCHBOX_CHANGE";
export const LIST_CUSTOMERS_SELECT = "LIST_CUSTOMERS_SELECT";
export const LIST_CUSTOMERS_EXPORT_START = "LIST_CUSTOMERS_EXPORT_START";
export const LIST_CUSTOMERS_EXPORT_SUCCESS = "LIST_CUSTOMERS_EXPORT_SUCCESS";

export const CHANGE_USER_STATUS_START = "CHANGE_USER_STATUS_START";
export const CHANGE_USER_STATUS_SUCCESS = "CHANGE_USER_STATUS_SUCCESS";
export const CHANGE_USER_STATUS_FAIL = "CHANGE_USER_STATUS_FAIL";

export const ASSIGN_ROLE_SUCCESS = "ASSIGN_ROLE_SUCCESS";
export const ASSIGN_ROLE_FAIL = "ASSIGN_ROLE_FAIL";
export const ASSIGN_ROLE_START = "ASSIGN_ROLE_START";
export const ASSIGN_ROLE_SELECT = "ASSIGN_ROLE_SELECT";
export const ASSIGN_ROLE_ADD = "ASSIGN_ROLE_ADD";
export const ASSIGN_ROLE_REMOVE = "ASSIGN_ROLE_REMOVE";
export const ASSIGN_ROLE_SUBMIT = "ASSIGN_ROLE_SUBMIT";

export const ASSIGN_ROLE_SUBMIT_SUCCESS = "ASSIGN_ROLE_SUBMIT_SUCCESS";
export const ASSIGN_ROLE_SUBMIT_START = "ASSIGN_ROLE_SUBMIT_START";
export const ASSIGN_ROLE_SUBMIT_FAIL = "ASSIGN_ROLE_SUBMIT_FAIL";

export const AVAILABLE_ROLE_SUCCESS = "AVAILABLE_ROLE_SUCCESS";
export const AVAILABLE_ROLE_FAIL = "AVAILABLE_ROLE_FAIL";
export const AVAILABLE_ROLE_START = "AVAILABLE_ROLE_START";
export const AVAILABLE_ROLE_SELECT = "AVAILABLE_ROLE_SELECT";
export const AVAILABLE_ROLE_ADD = "AVAILABLE_ROLE_ADD";
export const AVAILABLE_ROLE_REMOVE = "AVAILABLE_ROLE_REMOVE";

export const LOAD_USERINFO_SUCCESS = "LOAD_USERINFO_SUCCESS";
export const LOAD_USERINFO_FAIL = "LOAD_USERINFO_FAIL";
export const LOAD_USERINFO_START = "LOAD_USERINFO_START";

export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";
export const RESET_PASSWORD_START = "RESET_PASSWORD_START";
export const RESET_PASSWORD_CLEAR = "RESET_PASSWORD_CLEAR";

export const LIST_ROLES_SUCCESS = "LIST_ROLES_SUCCESS";
export const LIST_ROLES_FAIL = "LIST_ROLES_FAIL";
export const LIST_ROLES_START = "LIST_ROLES_START";
export const LIST_ROLES_SEARCHBOX_CHANGE = "LIST_ROLES_SEARCHBOX_CHANGE";
export const LIST_ROLES_SELECT = "LIST_ROLES_SELECT";
export const UPDATE_ROLES_PPERMISSIONS_SUCCESS =
  "UPDATE_ROLES_PPERMISSIONS_SUCCESS";
export const ADD_ROLES_PPERMISSIONS_SUCCESS = "ADD_ROLES_PPERMISSIONS_SUCCESS";

export const LIST_PERMISSIONS_SUCCESS = "LIST_PERMISSIONS_SUCCESS";
export const LIST_PERMISSIONS_FAIL = "LIST_PERMISSIONS_FAIL";
export const LIST_PERMISSIONS_START = "LIST_PERMISSIONS_START";
export const LIST_PERMISSIONS_SEARCHBOX_CHANGE =
  "LIST_PERMISSIONS_SEARCHBOX_CHANGE";
export const LIST_PERMISSIONS_SELECT = "LIST_PERMISSIONS_SELECT";
export const LIST_PERMISSIONS_Category_SELECT =
  "LIST_PERMISSIONS_Category_SELECT";
export const LIST_PERMISSIONS_RESETFORM = "LIST_PERMISSIONS_RESETFORM";

export const UPDATE_USERS_SUCCESS = "UPDATE_USERS_SUCCESS";
export const ADD_USERS_SUCCESS = "ADD_USERS_SUCCESS";
export const ADD_USERS_FAIL = "ADD_USERS_FAIL";
export const ADD_USERS_CHANGE_FORM = "ADD_USERS_CHANGE_FORM";

export const DELETE_ROLES_SUCCESS = "DELETE_ROLES_SUCCESS";
export const DELETE_ROLES_FAIL = "DELETE_ROLES_FAIL";
export const DELETE_ROLES_START = "DELETE_ROLES_START";

export const LIST_ORG_SUCCESS = "LIST_ORG_SUCCESS";
export const LIST_ORG_FAIL = "LIST_ORG_FAIL";
export const LIST_ORG_LOADING = "LIST_ORG_LOADING";

export const LIST_ORG_MNG_SUCCESS = "LIST_ORG_MNG_SUCCESS";
export const LIST_ORG_MNG_FAIL = "LIST_ORG_MNG_FAIL";
export const LIST_ORG_MNG_START = "LIST_ORG_MNG_START";
export const LIST_ORG_MNG_CLEAR = "LIST_ORG_MNG_CLEAR";
export const LIST_ORG_MNG_SELECT = "LIST_ORG_MNG_SELECT";
export const LIST_ORG_MNG_EXPORT_START = "LIST_ORG_MNG_EXPORT_START";
export const LIST_ORG_MNG_EXPORT_SUCCESS = "LIST_ORG_MNG_EXPORT_SUCCESS";

export const ADD_ORG_MNG_SUCCESS = "ADD_ORG_MNG_SUCCESS";
export const ADD_ORG_MNG_START = "ADD_ORG_MNG_START";

export const ACCESS_REPORT_SUCCESS = "ACCESS_REPORT_SUCCESS";
export const ACCESS_REPORT_START = "ACCESS_REPORT_START";
export const ACCESS_REPORT_FAIL = "ACCESS_REPORT_FAIL";

export const LIST_USER_ACTIVITY_LOG_SUCCESS = "LIST_USER_ACTIVITY_LOG_SUCCESS";
export const LIST_USER_ACTIVITY_LOG_FAIL = "LIST_USER_ACTIVITY_LOG_FAIL";
export const LIST_USER_ACTIVITY_LOG_START = "LIST_USER_ACTIVITY_LOG_START";

export const LIST_PARTNER_APPLICATION_SUCCESS =
  "LIST_PARTNER_APPLICATION_SUCCESS";
export const LIST_PARTNER_APPLICATION_FAIL = "LIST_PARTNER_APPLICATION_FAIL";
export const LIST_PARTNER_APPLICATION_START = "LIST_PARTNER_APPLICATION_START";
export const LIST_PARTNER_APPLICATION_SELECT =
  "LIST_PARTNER_APPLICATION_SELECT";
export const LIST_PARTNER_APPLICATION_EXPORT_START =
  "LIST_PARTNER_APPLICATION_EXPORT_START";
export const LIST_PARTNER_APPLICATION_EXPORT_SUCCESS =
  "LIST_PARTNER_APPLICATION_EXPORT_SUCCESS";

export const LIST_USER_ACTIVITY_SUCCESS = "LIST_USER_ACTIVITY_SUCCESS";
export const LIST_USER_ACTIVITY_FAIL = "LIST_USER_ACTIVITY_FAIL";
export const LIST_USER_ACTIVITY_START = "LIST_USER_ACTIVITY_START";
export const LIST_USER_ACTIVITY_SELECT = "LIST_USER_ACTIVITY_SELECT";
export const LIST_USER_ACTIVITY_EXPORT_START =
  "LIST_USER_ACTIVITY_EXPORT_START";
export const LIST_USER_ACTIVITY_EXPORT_SUCCESS =
  "LIST_USER_ACTIVITY_EXPORT_SUCCESS";

export const LOAD_PARTNER_APPLICATION_DETAIL_SUCCESS =
  "LOAD_PARTNER_APPLICATION_DETAIL_SUCCESS";
export const LOAD_PARTNER_APPLICATION_DETAIL_FAIL =
  "LOAD_PARTNER_APPLICATION_DETAIL_FAIL";
export const LOAD_PARTNER_APPLICATION_DETAIL_START =
  "LOAD_PARTNER_APPLICATION_DETAIL_START";

export const CHANGE_STATE_PARTNER_APPLICATION_DETAIL_SUCCESS =
  "CHANGE_STATE_PARTNER_APPLICATION_DETAIL_SUCCESS";
export const CHANGE_STATE_PARTNER_APPLICATION_DETAIL_FAIL =
  "CHANGE_STATE_PARTNER_APPLICATION_DETAIL_FAIL";
export const CHANGE_STATE_PARTNER_APPLICATION_DETAIL_START =
  "CHANGE_STATE_PARTNER_APPLICATION_DETAIL_START";
export const PARTNER_UPLOUAD_ATTACHMENT_SUCCESS =
  "PARTNER_UPLOUAD_ATTACHMENT_SUCCESS";
export const PARTNER_UPLOUAD_ATTACHMENT_FAIL =
  "PARTNER_UPLOUAD_ATTACHMENT_FAIL";
export const PARTNER_UPLOUAD_ATTACHMENT_START =
  "PARTNER_UPLOUAD_ATTACHMENTYPARTNER_UPLOUAD_ATTACHMENT_START";

export const DELETE_PARTNER_UPLOUAD_ATTACHMENT_SELECT =
  "DELETE_PARTNER_UPLOUAD_ATTACHMENT_SELECT";
export const DELETE_PARTNER_UPLOUAD_ATTACHMENT_EXPORT_START =
  "DELETE_PARTNER_UPLOUAD_ATTACHMENT_EXPORT_START";
export const DELETE_PARTNER_UPLOUAD_ATTACHMENT_EXPORT_SUCCESS =
  "DELETE_PARTNER_UPLOUAD_ATTACHMENT_SUCCESS";

export const LIST_PARTNER_APP_ATTACHMENT_SUCCESS =
  "LIST_PARTNER_APP_ATTACHMENT_SUCCESS";
export const LIST_PARTNER_APP_ATTACHMENT_FAIL =
  "LIST_PARTNER_APP_ATTACHMENT_FAIL";
export const LIST_PARTNER_APP_ATTACHMENT_START =
  "LIST_PARTNER_APP_ATTACHMENT_START";
export const LIST_PARTNER_APP_ATTACHMENT_SELECT =
  "LIST_PARTNER_APP_ATTACHMENT_SELECT";
export const LIST_PARTNER_APP_ATTACHMENT_EXPORT_START =
  "LIST_PARTNER_APP_ATTACHMENT_EXPORT_START";
export const LIST_PARTNER_APP_ATTACHMENT_EXPORT_SUCCESS =
  "LIST_PARTNER_APP_ATTACHMENT_EXPORT_SUCCESS";

export const LIST_WORKFLOW_LOG_SUCCESS = "LIST_WORKFLOW_LOG_SUCCESS";
export const LIST_WORKFLOW_LOG_FAIL = "LIST_WORKFLOW_LOG_FAIL";
export const LIST_WORKFLOW_LOG_START = "LIST_WORKFLOW_LOG_START";
export const LIST_WORKFLOW_LOG_SELECT = "LIST_WORKFLOW_LOG_SELECT";
export const LIST_WORKFLOW_LOG_EXPORT_START = "LIST_WORKFLOW_LOG_EXPORT_START";
export const LIST_WORKFLOW_LOG_EXPORT_SUCCESS =
  "LIST_WORKFLOW_LOG_EXPORT_SUCCESS";

export const DELETE_PARTNER_APP_ATTACHMENT_SUCCESS =
  "DELETE_PARTNER_APP_ATTACHMENT_SUCCESS";
export const DELETE_PARTNER_APP_ATTACHMENT_FAIL =
  "DELETE_PARTNER_APP_ATTACHMENT_FAIL";
export const DELETE_PARTNER_APP_ATTACHMENT_START =
  "DELETE_PARTNER_APP_ATTACHMENT_START";

export const FIND_PARTNER_APP_ATTACHMENT_SUCCESS =
  "FIND_PARTNER_APP_ATTACHMENT_SUCCESS";
export const FIND_PARTNER_APP_ATTACHMENT_FAIL =
  "FIND_PARTNER_APP_ATTACHMENT_FAIL";
export const FIND_PARTNER_APP_ATTACHMENT_START =
  "FIND_PARTNER_APP_ATTACHMENT_START";

export const LIST_VOUCHER_SUCCESS = "LIST_VOUCHER_SUCCESS";
export const LIST_VOUCHER_FAIL = "LIST_VOUCHER_FAIL";
export const LIST_VOUCHER_START = "LIST_VOUCHER_START";
export const LIST_VOUCHER_SELECT = "LIST_VOUCHER_SELECT";
export const LIST_VOUCHER_EXPORT_START = "LIST_VOUCHER_EXPORT_START";
export const LIST_VOUCHER_EXPORT_SUCCESS = "LIST_VOUCHER_EXPORT_SUCCESS";

export const LOAD_VOUCHER_INFO_SUCCESS = "LOAD_VOUCHER_INFO_SUCCESS";
export const LOAD_VOUCHER_INFO_FAIL = "LOAD_VOUCHER_INFO_FAIL";
export const LOAD_VOUCHER_INFO_START = "LOAD_VOUCHER_INFO_START";

export const CHANGE_STATUS_VOUCHER_INFO_SUCCESS =
  "CHANGE_STATUS_VOUCHER_INFO_SUCCESS";
export const CHANGE_STATUS_VOUCHER_INFO_FAIL =
  "CHANGE_STATUS_VOUCHER_INFO_FAIL";
export const CHANGE_STATUS_VOUCHER_INFO_START =
  "CHANGE_STATUS_VOUCHER_INFO_START";

export const ADD_VOUCHER_SUCCESS = "ADD_VOUCHER_SUCCESS";
export const ADD_VOUCHER_FAIL = "ADD_VOUCHER_FAIL";
export const ADD_VOUCHER_START = "ADD_VOUCHER_START";

export const ADD_VOUCHER_BATCH_SUCCESS = "ADD_VOUCHER_BATCH_SUCCESS";
export const ADD_VOUCHER_BATCH_FAIL = "ADD_VOUCHER_BATCH_FAIL";
export const ADD_VOUCHER_BATCH_START = "ADD_VOUCHER_BATCH_START";

export const EDIT_VOUCHER_SUCCESS = "EDIT_VOUCHER_SUCCESS";
export const EDIT_VOUCHER_FAIL = "EDIT_VOUCHER_FAIL";
export const EDIT_VOUCHER_START = "EDIT_VOUCHER_START";

export const SUBMIT_VOUCHER_PRODUCTS_SUCCESS =
  "SUBMIT_VOUCHER_PRODUCTS_SUCCESS";
export const SUBMIT_VOUCHER_PRODUCTS_FAIL = "SUBMIT_VOUCHER_PRODUCTS_FAIL";
export const SUBMIT_VOUCHER_PRODUCTS_START = "SUBMIT_VOUCHER_PRODUCTS_START";

export const PRODUCT_LIMIT_TO_VOUCHER_LIST_SUCCESS =
  "PRODUCT_LIMIT_TO_VOUCHER_LIST_SUCCESS";
export const PRODUCT_LIMIT_TO_VOUCHER_LIST_FAIL =
  "PRODUCT_LIMIT_TO_VOUCHER_LIST_FAIL";
export const PRODUCT_LIMIT_TO_VOUCHER_LIST_START =
  "PRODUCT_LIMIT_TO_VOUCHER_LIST_START";
export const ADD_PRODUCT_LIMIT_TO_VOUCHER = "ADD_PRODUCT_LIMIT_TO_VOUCHER";
export const REMOVE_PRODUCT_LIMIT_TO_VOUCHER =
  "REMOVE_PRODUCT_LIMIT_TO_VOUCHER";

export const VOUCHER_PRODUCTS_LIST_SUCCESS = "VOUCHER_PRODUCTS_LIST_SUCCESS";
export const VOUCHER_PRODUCTS_LIST_FAIL = "VOUCHER_PRODUCTS_LIST_FAIL";
export const VOUCHER_PRODUCTS_LIST_START = "VOUCHER_PRODUCTS_LIST_START";
export const SELECTE_VOUCHER_PRODUCT = "SELECTE_VOUCHER_PRODUCT";
export const SELECT_PRODUCT_LIMIT_TO_VOUCHER =
  "SELECT_PRODUCT_LIMIT_TO_VOUCHER";
export const REMOVE_VOUCHER_PRODUCT = "REMOVE_VOUCHER_PRODUCT";
export const ADD_VOUCHER_PRODUCT = "ADD_VOUCHER_PRODUCT";

export const LIST_VOUCHER_BATCH_SUCCESS = "LIST_VOUCHER_BATCH_SUCCESS";
export const LIST_VOUCHER_BATCH_FAIL = "LIST_VOUCHER_BATCH_FAIL";
export const LIST_VOUCHER_BATCH_START = "LIST_VOUCHER_BATCH_START";
export const LIST_VOUCHER_BATCH_SELECT = "LIST_VOUCHER_BATCH_SELECT";
export const LIST_VOUCHER_BATCH_EXPORT_START =
  "LIST_VOUCHER_BATCH_EXPORT_START";
export const LIST_VOUCHER_BATCH_EXPORT_SUCCESS =
  "LIST_VOUCHER_BATCH_EXPORT_SUCCESS";
export const VOUCHER_BATCH_CHANGE_SHOW_MODE = "VOUCHER_BATCH_CHANGE_SHOW_MODE";

export const PRINT_VOUCHER_CODE_SUCCESS = "PRINT_VOUCHER_CODE_SUCCESS";
export const PRINT_VOUCHER_CODE_FAIL = "PRINT_VOUCHER_CODE_FAIL";
export const PRINT_VOUCHER_CODE_START = "PRINT_VOUCHER_CODE_START";

export const LIST_VOUCHER_CODE_SUCCESS = "LIST_VOUCHER_CODE_SUCCESS";
export const LIST_VOUCHER_CODE_FAIL = "LIST_VOUCHER_CODE_FAIL";
export const LIST_VOUCHER_CODE_START = "LIST_VOUCHER_CODE_START";
export const LIST_VOUCHER_CODE_SELECT = "LIST_VOUCHER_CODE_SELECT";
export const LIST_VOUCHER_CODE_EXPORT_START = "LIST_VOUCHER_CODE_EXPORT_START";
export const LIST_VOUCHER_CODE_EXPORT_SUCCESS =
  "LIST_VOUCHER_CODE_EXPORT_SUCCESS";

export const VOID_VOUCHER_CODE_SUCCESS = "VOID_VOUCHER_CODE_SUCCESS";
export const VOID_VOUCHER_CODE_FAIL = "VOID_VOUCHER_CODE_FAIL";
export const VOID_VOUCHER_CODE_START = "VOID_VOUCHER_CODE_START";

export const LOAD_VOUCHER_CODE_INFO_SUCCESS = "LOAD_VOUCHER_CODE_INFO_SUCCESS";
export const LOAD_VOUCHER_CODE_INFO_FAIL = "LOAD_VOUCHER_CODE_INFO_FAIL";
export const LOAD_VOUCHER_CODE_INFO_START = "LOAD_VOUCHER_CODE_INFO_START";
export const LOAD_VOUCHER_CODE_INFO_CLEAR = "LOAD_VOUCHER_CODE_INFO_CLEAR";

export const VOID_VOUCHER_CODE_INFO_SUCCESS = "VOID_VOUCHER_CODE_INFO_SUCCESS";
export const VOID_VOUCHER_CODE_INFO_FAIL = "VOID_VOUCHER_CODE_INFO_FAIL";
export const VOID_VOUCHER_CODE_INFO_START = "VOID_VOUCHER_CODE_INFO_START";
export const VOID_VOUCHER_CODE_INFO_CLEAR = "VOID_VOUCHER_CODE_INFO_CLEAR";

export const VOID_RANGE_VOUCHER_CODE_INFO_SUCCESS =
  "VOID_RANGE_VOUCHER_CODE_INFO_SUCCESS";
export const VOID_RANGE_VOUCHER_CODE_INFO_FAIL =
  "VOID_RANGE_VOUCHER_CODE_INFO_FAIL";
export const VOID_RANGE_VOUCHER_CODE_INFO_START =
  "VOID_RANGE_VOUCHER_CODE_INFO_START";
export const VOID_RANGE_VOUCHER_CODE_INFO_CLEAR =
  "VOID_RANGE_VOUCHER_CODE_INFO_CLEAR";

export const FIND_TEMPLATE_SUCCESS = "FIND_TEMPLATE_SUCCESS";
export const FIND_TEMPLATE_FAIL = "FIND_TEMPLATE_FAIL";
export const FIND_TEMPLATE_START = "FIND_TEMPLATE_START";
export const FIND_TEMPLATE_CLEAR = "FIND_TEMPLATE_CLEAR";

export const FIND_BY_ID_TEMPLATE_SUCCESS = "FIND_BY_ID_TEMPLATE_SUCCESS";
export const FIND_BY_ID_TEMPLATE_FAIL = "FIND_BY_ID_TEMPLATE_FAIL";
export const FIND_BY_ID_TEMPLATE_START = "FIND_BY_ID_TEMPLATE_START";
export const FIND_BY_ID_TEMPLATE_CLEAR = "FIND_BY_ID_TEMPLATE_CLEAR";

export const FIND_BY_Assign_TEMPLATE_SUCCESS =
  "FIND_BY_Assign_TEMPLATE_SUCCESS";
export const FIND_BY_Assign_TEMPLATE_FAIL = "FIND_BY_Assign_TEMPLATE_FAIL";
export const FIND_BY_Assign_TEMPLATE_START = "FIND_BY_Assign_TEMPLATE_START";
export const FIND_BY_Assign_TEMPLATE_CLEAR = "FIND_BY_Assign_TEMPLATE_CLEAR";

export const ADD_TEMPLATE_SUCCESS = "ADD_TEMPLATE_SUCCESS";
export const ADD_TEMPLATE_FAIL = "ADD_TEMPLATE_FAIL";
export const ADD_TEMPLATE_START = "ADD_TEMPLATE_START";
export const ADD_TEMPLATE_CLEAR = "ADD_TEMPLATE_CLEAR";

export const RENDER_TEMPLATE_SUCCESS = "RENDER_TEMPLATE_SUCCESS";
export const RENDER_TEMPLATE_FAIL = "RENDER_TEMPLATE_FAIL";
export const RENDER_TEMPLATE_START = "RENDER_TEMPLATE_START";
export const RENDER_TEMPLATE_CLEAR = "RENDER_TEMPLATE_CLEAR";

export const LIST_LOYALTY_RULE_SUCCESS = "LIST_LOYALTY_RULE_SUCCESS";
export const LIST_LOYALTY_RULE_FAIL = "LIST_LOYALTY_RULE_FAIL";
export const LIST_LOYALTY_RULE_START = "LIST_LOYALTY_RULE_START";
export const LIST_LOYALTY_RULE_SELECT = "LIST_LOYALTY_RULE_SELECT";
export const LIST_LOYALTY_RULE_EXPORT_START = "LIST_LOYALTY_RULE_EXPORT_START";
export const LIST_LOYALTY_RULE_EXPORT_SUCCESS =
  "LIST_VOUCHER_CODE_EXPORT_SUCCESS";

export const ADD_LOYALTY_RULE_SUCCESS = "ADD_LOYALTY_RULE_SUCCESS";
export const ADD_LOYALTY_RULE_FAIL = "ADD_LOYALTY_RULE_FAIL";
export const ADD_LOYALTY_RULE_START = "ADD_LOYALTY_RULE_START";

export const EDIT_LOYALTY_RULE_SUCCESS = "EDIT_LOYALTY_RULE_SUCCESS";
export const EDIT_LOYALTY_RULE_FAIL = "EDIT_LOYALTY_RULE_FAIL";
export const EDIT_LOYALTY_RULE_START = "EDIT_LOYALTY_RULE_START";

export const EXTEND_VOUCHER_SUCCESS = "EXTEND_VOUCHER_SUCCESS";
export const EXTEND_VOUCHER_FAIL = "EXTEND_VOUCHER_FAIL";
export const EXTEND_VOUCHER_START = "EXTEND_VOUCHER_START";

export const ADD_BACK_OFFICE_PARAMS = "ADD_BACK_OFFICE_PARAMS";
export const ADD_BACK_OFFICE_PAYMENT_PARAMS = "ADD_BACK_OFFICE_PAYMENT_PARAMS";
export const ADD_BACK_OFFICE_ALL_STATIONS = "ADD_BACK_OFFICE_ALL_STATIONS";
export const SET_BACK_OFFICE_GENEREATE_DONE = "SET_BACK_OFFICE_GENEREATE_DONE";
export const CLEAN_BACK_OFFICE_STATE = "CLEAN_BACK_OFFICE_STATE";

export const ADD_TRAIN_STATUS_DETAIL_START = "ADD_TRAIN_STATUS_DETAIL_START";
export const ADD_TRAIN_STATUS_DETAIL_SUCCESS = "ADD_TRAIN_STATUS_DETAIL_SUCCESS";
export const ADD_TRAIN_STATUS_DETAIL_FAIL = "ADD_TRAIN_STATUS_DETAIL_FAIL";

export const EDIT_TRAIN_STATUS_DETAIL_START = "EDIT_TRAIN_STATUS_DETAIL_START";
export const EDIT_TRAIN_STATUS_DETAIL_SUCCESS = "EDIT_TRAIN_STATUS_DETAIL_SUCCESS";
export const EDIT_TRAIN_STATUS_DETAIL_FAIL = "EDIT_TRAIN_STATUS_DETAIL_FAIL";

export const UPDATE_APPROVAL_LIST_COUNT = 'UPDATE_APPROVAL_LIST_COUNT';
